import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

// 查询黑白名单列表
export function selectVehicle(params) {
    return request.post('/operation/blackWhiteList?' + objectToQueryString(params));
}

//删除黑白名单
export function deleteParking(objectID) {
    return request.post('/operation/delBlackWhiteList?id=' + objectID);
}

//添加黑白名单
export function addParking(data) {
    return request.post('/operation/addBlackWhiteList', data);
}

//批量上传
export function doUploadAnalysisExcel(params) {
    return request.post('/blackWhiteListController/douploadeanalysisxcel', params);
}

//保存批量上传
export function affirm(params) {
    return request.post('/blackWhiteListController/affirm', params);
}
